// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import helpers from "@/helpers";
import { anagraficaPacchettiCategorie } from "@/models/carrello/categoria";
import { datiViaggio } from "@/models/carrello/datiViaggio";
import { associaDistributoriPacchettiExtended } from "@/models/carrello/prodotti";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { debug } from "console";
import { Component } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";

const _ENDPONT_PREVENTIVATORE = api.endpoint.CARRELLO.PREVENTIVATORE;

@Component({
	computed: {
		...mapState({
			datiViaggio: (state: any) => state.datiViaggio
		}),
	},
	methods: {
		...mapMutations(['setProdotti', 'setCategoria', 'setItemsSelected'])
	}
})
export default class preventivatoreiPage extends baseContextComponent {

	titleInfoProdotto: string = "Informazioni sul Prodotto"

	title: string = "";
	subtitle: string = "Compila i campi sottostanti";
	subtitleRed: string = "per scoprire la polizza adatta alle tue esigenze";
	datiViaggio!: datiViaggio;

	setProdotti!: (prodotti: associaDistributoriPacchettiExtended[]) => void;
	setCategoria!: (categoria: anagraficaPacchettiCategorie) => void;
	setItemsSelected!: (itemsSelected: number[]) => void;

	created() {
		this.getData();
	}

	mounted() { }

	getData() {

	}

	routerGoBack() {
		this.$router.go(-1);
	}

	onChangeDate() { }

	async goListaProdotti() {
		// richiamare servizio con parametri selezionati e poi andare alla lista prodotti


		var par = helpers.cloneObject(this.datiViaggio.parametri);

		par.destinazioneStato = par.destinazioneStato ? ((par.destinazioneStato as any).value as number) || null : null;
		par.destinazioneGruppoStato = par.destinazioneGruppoStato ? ((par.destinazioneGruppoStato as any).value as number) || null : null;
		par.nazionalita = par.nazionalita ? ((par.nazionalita as any).value as number) || null : null;
		par.eta = par.eta ? ((par.eta as any).value as number) || null : null;
		var isImportoObbligatorioPerTipologia = par.tipologiaPolizza ? ((par.tipologiaPolizza as any).isImportoObbligatorio	as boolean) || false : false;
		par.tipologiaPolizza = par.tipologiaPolizza ? ((par.tipologiaPolizza as any).value as number) || null : null;


		if (par.inizioViaggio && par.inizioViaggio && par.inizioViaggio > par.fineViaggio) {
			this.$bvToast.toast("La data di inizio viaggio non può essere successiva a quella di inizio viaggio", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			return;
		}

		if (par.tipologiaPolizza && isImportoObbligatorioPerTipologia && (par.importoViaggio == '' || isNaN(par.importoViaggio) || parseInt(par.importoViaggio) <= 0 || isNaN(parseInt(par.importoViaggio)))) {
			this.$bvToast.toast(`Per la tipologia di polizza da filtrare l'importo è obbligatorio`, { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			return;
		}

		var res = await api.chimataPost<associaDistributoriPacchettiExtended[]>(`${_ENDPONT_PREVENTIVATORE}`, par);

		this.setProdotti(res.data);
		this.setCategoria(null);
		this.setItemsSelected(res.data.map((obj) => { return obj.pacchettoID }));

		this.$router.push({ path: 'listaProdotti' as string, query: { "readonly": "true" } });
	}
}
